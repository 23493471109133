import React from "react"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { css } from "@emotion/core"

// Components
import { PrimaryButton } from "./PrimaryButton"

export const FooterCTA = () => (
  <Container
    css={css`
      text-align: center;
      padding-bottom: 10rem;
    `}
  >
    <Row>
      <Col>
        <h2
          css={css`
            color: #3d4051;
            font-family: "Quicksand", Sans-serif;
            font-size: 3rem;
            margin-bottom: 1rem;
            @media (max-width: 991px) and (min-width: 768px) {
              font-size: 2.5rem;
            }
            @media (max-width: 767px) and (min-width: 576px) {
              font-size: 2rem;
            }
            @media (max-width: 575px) {
              font-size: 1.2rem;
            }
          `}
        >
          Ready to grow your business?
        </h2>
        <PrimaryButton name="Let's Go!" to="/lets-talk/" />
      </Col>
    </Row>
  </Container>
)

export default FooterCTA

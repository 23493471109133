import React from "react"
import { Link } from "gatsby"

// CSS-in-JS & Bootstrap
import Button from "react-bootstrap/Button"
import { css } from "@emotion/core"

export const PrimaryButton = ({ name, to }) => (
  <Button
    as={Link}
    to={to}
    className="shadow-none"
    css={css`
      background: linear-gradient(150deg, #d25e5f, #d25e5f 50%, #ba5858);
      font-family: "Century Gothic Bold";
      font-size: 1.5rem;
      text-transform: uppercase;
      border: none;
      border-radius: 10px;
      padding: 0.5rem 2rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: linear-gradient(150deg, #ba5858, #ba5858 50%, #d25e5f);
        transform: translateY(-2px);
      }

      @media (max-width: 991px) and (min-width: 768px) {
        font-size: 1.2rem;
      }

      @media (max-width: 767px) {
        font-size: 1rem;
      }
    `}
  >
    {name}
  </Button>
)
